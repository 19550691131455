import headers from "../../services/api";
import auth from "../../services/authService";
import {
  LOGIN_BEGIN,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  REMOVE_TOKEN,
  SET_TOKEN,
} from "./types";

const TOKEN_STORAGE_KEY = "TOKEN_STORAGE_KEY";
const isProduction = false;
// const isProduction = process.env.NODE_ENV === 'production';

const initialState = {
  token: null,
  authenticating: false,
  error: false,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  authStatus: (state) => state.status,
};

const actions = {
  async login({ commit }, { username, password }) {
    let response;
    commit(LOGIN_BEGIN);
    try {
      response = await auth.login(username, password);
      commit(SET_TOKEN, response.data.key);
      commit(LOGIN_SUCCESS);
      return response;
    } catch (error) {
      console.error(error);
      commit(LOGIN_FAILURE);
      throw error;
    }
  },
  logout({ commit }) {
    return auth
      .logout()
      .then(() => commit(LOGOUT))
      .finally(() => commit(REMOVE_TOKEN));
  },

  async changePassword(_, { new_password1, new_password2 }) {
    let response;
    try {
      response = await auth.changeAccountPassword(new_password1, new_password2);
      // commit("CHANGE_PASSWORD_SUCCESS");
      return response;
    } catch (error) {
      // commit("CHANGE_PASSWORD_FAILURE");
      console.error(error);
      throw error;
    }
  },

  initialize({ commit }) {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);

    if (isProduction && token) {
      commit(REMOVE_TOKEN);
    }

    if (!isProduction && token) {
      commit(SET_TOKEN, token);
    }
  },
};

const mutations = {
  [LOGIN_BEGIN](state) {
    state.authenticating = true;
    state.error = false;
  },
  [LOGIN_FAILURE](state) {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    delete headers.defaults.headers.Authorization;
    state.authenticating = false;
    state.error = true;
  },
  [LOGIN_SUCCESS](state) {
    state.authenticating = false;
    state.error = false;
  },
  [LOGOUT](state) {
    state.authenticating = false;
    state.error = false;
  },
  [SET_TOKEN](state, token) {
    // if (!isProduction) localStorage.setItem(TOKEN_STORAGE_KEY, token);
    localStorage.setItem(TOKEN_STORAGE_KEY, token);
    headers.defaults.headers.Authorization = `Token ${token}`;
    state.token = token;
  },
  [REMOVE_TOKEN](state) {
    localStorage.removeItem(TOKEN_STORAGE_KEY);
    delete headers.defaults.headers.Authorization;
    state.token = null;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
