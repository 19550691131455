import api from "@/services/api";

export default {
  fetchAbsences() {
    return api.get(`absences/`).then((response) => response.data);
  },
  fetchAbsence(absenceId) {
    return api.get(`absences/${absenceId}/`).then((response) => response.data);
  },
  fetchAbsencesbyParams(params) {
    return api.get(`absences/`, { params }).then((response) => response.data);
  },
  postAbsence(payload) {
    return api.post(`absences/`, payload).then((response) => response.data);
  },
  deleteAbsence(absenceId) {
    return api
      .delete(`absences/${absenceId}/`)
      .then((response) => response.data);
  },
  sendAbsenceEmail() {
    return api.get(`sendabsence/`).then((response) => response.data);
  },
};
