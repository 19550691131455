import kpiListService from "../../services/kpiListService";
import kpiRecordService from "../../services/kpiRecordService";

const state = () => ({
  kpilist: [],
  kpirecords: [],
});

const getters = {};

const actions = {
  // KPI List
  async getKpiList({ commit }) {
    try {
      const response = await kpiListService.fetchKpiList();
      const data = response.results;
      commit("setKpiList", data);
    } catch (error) {
      console.error(error);
    }
  },
  // KPI Records
  async getKpiRecord({ commit }, kpiRecordId) {
    try {
      const response = await kpiRecordService.fetchKpiRecord(kpiRecordId);
      const data = response.results;
      commit("setKpiRecords", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getKpiRecordsbyParams({ commit }, params) {
    try {
      const response = await kpiRecordService.fetchKpiRecordsbyParams(params);
      const data = response.results;
      commit("setKpiRecords", data);
    } catch (error) {
      console.error(error);
    }
  },
  async addKpiRecords({ commit }, kpirecord) {
    try {
      const response = await kpiRecordService.postKpiRecord(kpirecord);
      console.log("COMMIT ADD KPIS");
      commit("addKpiRecords", response);
    } catch (error) {
      console.error(error);
    }
  },
  // eslint-disable-next-line no-unused-vars
  async deleteKpiRecords({ commit }, kpiRecordsIds) {
    console.log("DATA PARAM", kpiRecordsIds);
    const promises = kpiRecordsIds.map(async (item) => {
      return await kpiRecordService.deleteKpiRecord(item);
    });

    await Promise.all(promises)
      .then(() => {
        console.log("DONE DELETING");
        commit("deleteKpiRecords", kpiRecordsIds);
      })
      .catch((error) => {
        console.log("ERROR PROMISES");
        console.error(error);
      });
  },
};

const mutations = {
  setKpiList(state, kpilist) {
    state.kpilist = kpilist;
  },
  setKpiRecords(state, kpirecords) {
    state.kpirecords = kpirecords;
  },
  addKpiRecords(state, kpirecord) {
    state.kpirecords = [...state.kpirecords, ...kpirecord];
  },
  deleteKpiRecords(state, kpiRecordsIds) {
    state.kpirecords = state.kpirecords.filter(
      (item) => !kpiRecordsIds.includes(item.id)
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
