<template>
  <div id="app">
    <div
      class="fixed z-index-top bottom-0 left-0 right-0 bg-black text-white px-10 py-8 flex justify-between items-center"
      v-if="updateExists"
    >
      An update is available
      <button
        @click="refreshApp"
        class="bg-white px-4 py-2 text-black rounded-full"
      >
        Update now
      </button>
    </div>

    <router-view />
  </div>
</template>

<script>
import api from "@/services/api";

export default {
  data() {
    return {
      registration: null,
      updateExists: false,
    };
  },
  created() {
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status == 401) {
          this.$store.dispatch("auth/logout").then(() => {
            this.$router.push("login");
          });
        } else return Promise.reject(error);
      }
    );

    document.addEventListener("swUpdated", this.updateAvailable, {
      once: true,
    });

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      // Here the actual reload of the page occurs
      window.location.reload();
    });
  },
  mounted() {
    this._keyEvent = (e) => {
      const target = e.target.tagName.toLowerCase();
      if (target === "input" || target === "textarea") {
        return;
      }
      if (e.key === "h") {
        if (this.$router.currentRoute.path === "/") return;
        this.$router.push({ path: "home" });
      } else if (e.key === "p") {
        if (this.$router.currentRoute.path === "/projects") return;
        this.$router.push({ path: "projects" });
      } else if (e.key === "m") {
        if (this.$router.currentRoute.path === "/profile") return;
        this.$router.push({ path: "profile" });
      }
    };
    document.addEventListener("keydown", this._keyEvent);
  },
  methods: {
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
    },
  },

  beforeDestroy() {
    document.removeEventListener("keydown", this._keyEvent);
  },
};
</script>

<style>
.z-index-top {
  z-index: 1000;
}
html,
body,
#app {
  height: 100%;
}

button,
a {
  -webkit-tap-highlight-color: transparent;
}

button:focus,
a:focus {
  outline: none !important;
}
</style>
