<template>
  <div class="home flex flex-col h-full">
    <transition
      enter-active-class="transform transition-all duration-200 ease-in-out"
      leave-active-class="transform transition-all duration-150 ease-in-out"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <KPIModal v-if="showKpiModal" @close-modal="closeKPIModal"></KPIModal>
    </transition>
    <PageHeader>
      <div class="flex items-center">
        <router-link
          to="profile"
          class="px-2 -ml-2 py-1 inline-block focus:outline-none"
        >
          <img
            v-if="this.currentStaff.userImg"
            class="h-8 w-8 rounded-full object-cover"
            :src="this.currentStaff.userImg"
            alt=""
          />
          <div v-else class="h-8 w-8 bg-brand-gray-200 rounded-full"></div>
        </router-link>
      </div>

      <button class="font-medium" @click="selectDate(new Date())">
        {{ format(this.selectedDate, "eee, do MMMM") }}
      </button>

      <div class="flex items-center justify-end">
        <router-link
          to="projects"
          class="opacity-75 text-right focus:outline-none py-1 px-2 -mr-2"
        >
          Projects
        </router-link>
      </div>
    </PageHeader>
    <main class="flex-1 flex flex-col h-full max-h-full bg-brand-gray-100">
      <transition
        enter-active-class="transition-all duration-200 ease-in-out"
        leave-active-class="transition-all duration-150 ease-in-out"
        enter-class="opacity-0 translate-y-2"
        enter-to-class="opacity-100 translate-y-0"
        leave-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-2"
      >
        <TotalHoursSendButton
          class="fixed z-50 bottom-0 right-0 mb-6 mr-6 transform"
          :totalHours="totalHoursDate"
          :dateRecords="localSelectedDateRecords"
          @send-workforce-records="sendWorkforceRecords"
          v-show="
            this.localSelectedDateRecords.some(
              (record) =>
                (record.updated === true &&
                  (record.id != undefined || record.hours > 0)) ||
                record.hours > 0
            )
          "
        ></TotalHoursSendButton>
      </transition>

      <RowDaysCalendar
        v-show="projectsViewIsExpanded"
        @select-date="selectDate"
        ref="weekview"
      ></RowDaysCalendar>

      <div class="flex-1" v-show="!projectsViewIsExpanded">
        <div class="mt-4">
          <v-date-picker
            ref="picker"
            class="home-calendar"
            @input="selectDate"
            @update:from-page="updatePageCalendar"
            is-inline
            is-required
            is-expanded
            :value="selectedDate"
            :select-attribute="selectAttribute"
            :first-day-of-week="2"
            :theme="vCalTheme"
            color="gray"
            :attributes="calendarAttributes"
            :min-date="minDate"
            :max-date="maxDate"
          >
          </v-date-picker>
        </div>
      </div>

      <div
        class="over-card fixed left-0 right-0 bg-white w-full flex flex-col transition-transform duration-300 z-20"
        :class="{ 'projects-bottom-state': !projectsViewIsExpanded }"
      >
        <div
          class="flex-shrink-0 flex justify-center items-center border-b border-brand-gray-200"
        >
          <button
            class="drag-handle px-10 py-4 w-full flex justify-center items-center text-center focus:outline-none"
            @click="toggleProjectsCard"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 24"
              class="h-5 transform"
              :class="[projectsViewIsExpanded ? 'rotate-180' : '']"
            >
              <path
                d="M10.5 15.5l13.5-7 13.5 7"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>

        <div class="pt-1 pb-2 px-4 flex-shrink-0">
          <SearchBar
            :searchValue="projectSearch"
            @set-search="setProjectSearch"
          ></SearchBar>
        </div>

        <ul
          class="overflow-y-scroll overflow-x-hidden py-1 flex-1"
          v-if="activestaffprojects.length"
        >
          <ProjectRecordCard
            v-for="activeproject in searchActivestaffprojects"
            :key="activeproject.id"
            :activeproject="activeproject"
            @modify-record="modifyRecord"
            :workforcerecord="
              localSelectedDateRecords.find(
                (record) => record.project === activeproject.project
              )
            "
          ></ProjectRecordCard>
          <li
            class="w-full"
            :class="[
              projectsViewIsExpanded
                ? 'container-bottom-projects-margin'
                : 'container-bottom-projects-margin-expanded',
            ]"
          ></li>
          <!-- <div class="projects-margin-bottom">HELLO</div> -->
        </ul>

        <div v-else class="overflow-y-scroll h-40">
          <div class="h-10 text-center mx-2">
            <p class="mt-4">
              You don’t have any active projects. You will soon though.
            </p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  formatISO,
  isSameDay,
  startOfDay,
  startOfMonth,
  sub,
} from "date-fns";
import { mapActions, mapState } from "vuex";

import KPIModal from "@/components/home/KPIModal";
import ProjectRecordCard from "@/components/home/ProjectRecordCard";
import RowDaysCalendar from "@/components/home/RowDaysCalendar";
import TotalHoursSendButton from "@/components/home/TotalHoursSendButton";
import PageHeader from "@/components/PageHeader";
import SearchBar from "@/components/SearchBar";

export default {
  name: "Home",
  components: {
    ProjectRecordCard,
    PageHeader,
    KPIModal,
    RowDaysCalendar,
    TotalHoursSendButton,
    SearchBar,
  },
  data() {
    return {
      format,
      startOfDay,
      statuses: {
        IDLE: "idle",
        SENDING_RECORDS: "sending_records",
        SENDING_KPIS: "sending_kpis",
      },
      status: "idle",
      minDate: "",
      maxDate: "",
      projectSearch: "",
      localSelectedDateRecords: [],
      projectsViewIsExpanded: true,
      showKpiModal: false,
      selectAttribute: {
        highlight: {
          class: "selected-day",
          contentClass: "highlight-text-black",
        },
      },
      vCalTheme: {
        container: {
          light: "bg-transparent", // Classes to apply for light mode
          dark: "bg-black", // Classes to apply for dark mode
        },
        header: {
          light: "mb-6",
          dark: "vc-text-gray-200",
        },
        weekdays: {
          light: "vc-text-sm vc-text-black",
          dark: "vc-text-sm vc-text-{color}-200",
        },
        datePickerInput:
          "vc-appearance-none vc-text-base vc-text-gray-800 vc-bg-white vc-border vc-border-gray-400 vc-rounded vc-w-full vc-py-2 vc-px-3 vc-leading-tight focus:vc-outline-none focus:vc-shadow",
      },
    };
  },
  async created() {
    // const today = formatISO(new Date(), { representation: "date" });

    this.minDate = sub(startOfMonth(new Date()), {
      months: 2,
    });
    this.maxDate = add(endOfMonth(new Date()), {
      months: 2,
    });

    const startDate = formatISO(this.minDate, { representation: "date" });
    const endDate = formatISO(this.maxDate, { representation: "date" });

    this.generateDateRange(28);

    if (Object.keys(this.currentStaff).length === 0) {
      await this.getCurrentStaff();
    }

    await this.getWorkforceRecordsbyParams({
      staff: this.currentStaff.id,
      dateRange_after: startDate,
      dateRange_before: endDate,
    });

    if (this.activestaffprojects.length === 0) {
      await this.getActiveStaffProjectsbyParams({
        staff: this.currentStaff.id,
      });
    }

    if (this.globalHolidays.length === 0) {
      await this.getGlobalHolidaysbyParams({
        dateRange_after: startDate,
        dateRange_before: endDate,
      });
    }

    await this.getHolidaysbyParams({
      staff: this.currentStaff.id,
      status: "approved",
      dateRange_after: startDate,
      dateRange_before: endDate,
    });

    await this.getAbsencesbyParams({
      staff: this.currentStaff.id,
      dateRange_after: startDate,
      dateRange_before: endDate,
    });

    this.generateAttributes();

    this.localSelectedDateRecords = JSON.parse(
      JSON.stringify(this.selectedDateRecords)
    );
  },
  mounted() {
    this.$nextTick(() => {
      const container = this.$refs.weekview;
      if (container.$el.childNodes.length) {
        container.$el.childNodes[
          container.$el.childNodes.length - 1
        ].scrollIntoView();
      } else {
        container.scrollLeft = container.scrollWidth;
      }
    });
  },
  computed: {
    ...mapState("activestaffprojects", ["activestaffprojects"]),
    ...mapState("staff", ["currentStaff"]),
    ...mapState("workforcerecords", ["workforcerecords"]),
    ...mapState("globalholidays", ["globalHolidays"]),
    ...mapState("holidays", ["holidays"]),
    ...mapState("absences", ["absences"]),
    ...mapState("calendar", [
      "selectedDate",
      "dateRanges",
      "calendarAttributes",
      "unfilledDays",
    ]),

    selectedDateRecords() {
      return this.workforcerecords.filter((record) =>
        isSameDay(new Date(record.date), this.selectedDate)
      );
    },
    searchActivestaffprojects() {
      return this.activestaffprojects.filter((project) =>
        project.projectData.projectName
          .toLowerCase()
          .includes(this.projectSearch.toLowerCase())
      );
    },
    totalHoursDate() {
      if (!this.localSelectedDateRecords.length) {
        return 0;
      } else
        return this.localSelectedDateRecords
          .map((record) => Number(record.hours))
          .reduce((a, b) => a + b);
    },
  },
  methods: {
    ...mapActions("staff", ["getCurrentStaff"]),
    ...mapActions("activestaffprojects", ["getActiveStaffProjectsbyParams"]),
    ...mapActions("workforcerecords", [
      "getWorkforceRecordsbyParams",
      "addWorkforceRecords",
      "deleteWorkforceRecords",
    ]),
    ...mapActions("globalholidays", ["getGlobalHolidaysbyParams"]),
    ...mapActions("holidays", ["getHolidaysbyParams"]),
    ...mapActions("absences", ["getAbsencesbyParams"]),
    ...mapActions("calendar", ["getUnfilledDays"]),
    toggleProjectsCard() {
      this.projectsViewIsExpanded = !this.projectsViewIsExpanded;
      const container = this.$refs.weekview;

      const selected = container.$el.querySelectorAll(
        `[data-date='${this.selectedDate}']`
      );

      if (selected.length) {
        this.$nextTick(() => {
          selected[0].scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center",
          });
        });
      }
    },
    setProjectSearch(value) {
      this.projectSearch = value;
    },
    modifyRecord(record, action, num) {
      if (action === "" || action === undefined || action === null) {
        console.warn("You should give a modify action");
        return;
      }

      if (action === "decrease") {
        if (this.totalHoursDate <= 0) {
          // console.log("TOO LITTLE HOURS TODAY");
          return;
        }
        record.hours = Number(record.hours) - 0.5;
      } else if (action === "increase") {
        if (this.totalHoursDate >= 24) {
          // console.log("TOO MUCH HOURS TODAY");
          return;
        }
        record.hours = Number(record.hours) + 0.5;
      } else if (action === "swap") {
        // console.log("Swap by number", num);
        if (num >= 0 && num <= 24) {
          record.hours = num;
        } else if (num > 24) {
          record.hours = 24;
        } else {
          record.hours = 0;
        }
      }
      record.updated = true;

      if (
        this.localSelectedDateRecords.find(
          (recordFound) => record.project === recordFound.project
        )
      ) {
        return;
        // console.log("ALREADY THERE", record);
      } else {
        this.localSelectedDateRecords.push(record);
        // console.log("NEW", record);
      }
    },
    generateDateRange(numDays) {
      const start = sub(new Date(), {
        days: numDays,
      });

      const days = eachDayOfInterval({
        start: start,
        end: new Date(),
      });

      this.$store.commit("calendar/setDateRanges", days);
    },
    async generateAttributes() {
      const attr = [];

      if (this.holidays.length) {
        attr.push({
          key: "holidays",
          highlight: {
            class: "highlight-yellow",
            contentClass: "highlight-text-black",
          },
          dates: this.holidays.map((holiday) => {
            const date = holiday.date;
            return date;
          }),
        });
      }
      if (this.globalHolidays.length) {
        attr.push({
          key: "globalholidays",
          highlight: {
            class: "highlight-purple",
            contentClass: "highlight-text-black",
          },
          dates: this.globalHolidays.map((globalHoliday) => {
            const date = globalHoliday.date;
            return date;
          }),
        });
      }
      if (this.absences.length) {
        attr.push({
          key: "absences",
          dot: "green",
          dates: this.absences.map((absence) => {
            const date = absence.date;
            return date;
          }),
        });
      }

      // Days with filled workforce
      if (this.workforcerecords.length) {
        attr.push({
          key: "workforcerecord",
          dates: this.workforcerecords.map((workforcerecord) => {
            const date = workforcerecord.date;
            return date;
          }),
        });
      }

      const today = formatISO(new Date(), { representation: "date" });
      const startDate = formatISO(this.minDate, { representation: "date" });
      const range = {
        start: startDate,
        end: today,
      };

      await this.getUnfilledDays({
        range,
        attributes: attr,
      });

      attr.push({
        key: "unfilledDays",
        dates: this.unfilledDays,
        highlight: {
          class: "dot-red",
          contentClass: "highlight-text-black",
        },
      });

      this.$store.commit("calendar/setCalendarAttributes", attr);
    },
    async selectDate(day) {
      if (isSameDay(day, this.selectedDate)) return;
      this.$store.commit("calendar/selectNewDate", day);

      const picker = this.$refs.picker;
      await picker.$refs.calendar.focusDate(day);

      this.localSelectedDateRecords = JSON.parse(
        JSON.stringify(this.selectedDateRecords)
      );
    },
    updatePageCalendar(page) {
      console.log("PAGE UPDATED", page);
    },
    async sendWorkforceRecords() {
      if (this.status != this.statuses.IDLE) return;

      this.status = this.statuses.SENDING_RECORDS;
      const date = formatISO(this.selectedDate, { representation: "date" });

      console.log("RECORDS", this.localSelectedDateRecords);

      const updatedRecords = this.localSelectedDateRecords.filter(
        (record) =>
          record.updated === true &&
          (record.id != undefined || record.hours > 0)
      );
      console.log("ITEMS", updatedRecords);

      if (!updatedRecords.length) return;

      console.log(
        `This records ${updatedRecords} have been updated for selected ${date}`
      );

      const wkfRemove = updatedRecords
        .filter((project) => project.id != undefined)
        .map((project) => project.id);

      console.log("To remove", wkfRemove);
      if (wkfRemove.length) {
        await this.deleteWorkforceRecords(wkfRemove);
        console.log("DELETION FINISHED");
      }

      const wkfAdd = updatedRecords
        .filter((project) => project.hours > 0)
        .map((project) => ({
          date,
          staff: this.currentStaff.url,
          project: project.project,
          hours: project.hours,
          wkfCost: 0,
        }));

      console.log("To add", wkfAdd);
      if (wkfAdd.length) {
        await this.addWorkforceRecords(wkfAdd);
        console.log("CREATION FINISHED");
      }

      const today = formatISO(new Date(), { representation: "date" });
      const startDate = formatISO(this.minDate, { representation: "date" });

      await this.getWorkforceRecordsbyParams({
        staff: this.currentStaff.id,
        dateRange_after: startDate,
        dateRange_before: today,
      });

      console.log("UPDATED");
      this.localSelectedDateRecords = JSON.parse(
        JSON.stringify(this.selectedDateRecords)
      );

      this.generateAttributes();

      this.status = this.statuses.SENDING_KPIS;
      this.showKpiModal = true;
    },
    closeKPIModal() {
      this.showKpiModal = false;
      this.status = this.statuses.IDLE;
    },
  },
};
</script>

<style scoped>
.over-card {
  box-shadow: 0 -5px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 3rem 3rem 0 0;
  height: calc(100% - 170px);
  top: 170px;
}

.projects-bottom-state {
  transform: translate3d(0, 290px, 0);
}

/* .pb-projects {
  padding-bottom: 90px;
}
.pb-projects-expanded {
  padding-bottom: 390px;
} */

.container-bottom-projects-margin {
  height: 100px;
}

.container-bottom-projects-margin-expanded {
  height: 390px;
}
</style>
<style>
.home-calendar.vc-container {
  --day-content-height: 2.8rem !important;
  --day-content-width: 2.8rem !important;
  --highlight-height: 2.8rem !important;
  --highlight-width: 2.8rem !important;
}

.home-calendar.vc-day-box-center-bottom {
  margin-bottom: 6px;
}

.highlight-yellow {
  background-color: rgb(246, 224, 94) !important;
}

.highlight-purple {
  background-color: rgb(159, 122, 234) !important;
}

.dot-red {
  position: relative;
  background-color: transparent !important;
}
.dot-red::before {
  content: "🔥";
  position: absolute;
  right: -0.05rem;
  top: -0.1rem;
  z-index: 30;
}

.highlight-text-black {
  color: black !important;
}

.highlight-black {
  background-color: black !important;
}

.selected-day {
  border: 1.5px solid black;
  background: transparent !important;
}
</style>
