import projectService from "../../services/projectService";

const DDSclientAlias = `${process.env.VUE_APP_API_BACK_URL}/api/v1/clients/${process.env.VUE_APP_DDS_CLIENT_ALIAS}/`;

const state = {
  projects: [],
  currentProjectStats: {},
};

const getters = {
  projects: (state) => {
    return state.projects;
  },
  projectsByStaffActiveStatus(state, getters, rootState) {
    const userProjects = [];
    state.projects.forEach((project) => {
      const found = rootState.activestaffprojects.activestaffprojects.find(
        (element) => element.project === project.url
      );
      if (found) {
        const projectUserStatus = {
          staffProjectActive: true,
          staffProjectActiveId: found.id,
        };
        userProjects.push({ project, staffStatus: projectUserStatus });
      } else {
        userProjects.push({
          project,
          staffStatus: { staffProjectActive: false },
        });
      }
    });
    return userProjects;
  },
  internalProjectsByStaffActiveStatus(state, getters, rootState) {
    const userProjects = [];

    const internalProjects = state.projects.filter(
      (project) => project.clientAlias === DDSclientAlias
    );
    internalProjects.forEach((project) => {
      const found = rootState.activestaffprojects.activestaffprojects.find(
        (element) => element.project === project.url
      );
      if (found) {
        const projectUserStatus = {
          staffProjectActive: true,
          staffProjectActiveId: found.id,
        };
        userProjects.push({ project, staffStatus: projectUserStatus });
      } else {
        userProjects.push({
          project,
          staffStatus: { staffProjectActive: false },
        });
      }
    });
    return userProjects;
  },
  clientsProjectsByStaffActiveStatus(state, getters, rootState) {
    const userProjects = [];

    const externalProjects = state.projects.filter(
      (project) => project.clientAlias != DDSclientAlias
    );
    externalProjects.forEach((project) => {
      const found = rootState.activestaffprojects.activestaffprojects.find(
        (element) => element.project === project.url
      );
      if (found) {
        const projectUserStatus = {
          staffProjectActive: true,
          staffProjectActiveId: found.id,
        };
        userProjects.push({ project, staffStatus: projectUserStatus });
      } else {
        userProjects.push({
          project,
          staffStatus: { staffProjectActive: false },
        });
      }
    });
    return userProjects;
  },
};

const actions = {
  async getProjects({ commit }) {
    try {
      const response = await projectService.fetchProjects();
      const data = response.results;
      commit("setProjects", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getProject({ commit }, projectId) {
    try {
      const response = await projectService.fetchProject(projectId);
      const data = response.results;
      commit("setProjects", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getProjectsbyParams({ commit }, params) {
    try {
      const response = await projectService.fetchProjectsbyParams(params);
      const data = response.results;
      commit("setProjects", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getProjectStats({ commit }, projectId) {
    try {
      const response = await projectService.fetchProjectStats(projectId);
      response.project_id = projectId;
      commit("setProjectStats", response);
    } catch (error) {
      console.error(error);
    }
  },
  async addProject({ commit }, project) {
    try {
      const response = await projectService.postProject(project);
      commit("addProject", response);
    } catch (error) {
      console.error(error);
    }
  },
  async deleteProject({ commit }, projectId) {
    try {
      const response = await projectService.deleteProject(projectId);
      commit("deleteProject", response);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setProjects(state, projects) {
    state.projects = projects;
  },
  setProjectStats(state, projectStats) {
    state.currentProjectStats = projectStats;
  },
  addProject(state, project) {
    state.projects.push(project);
  },
  deleteProject(state, projectId) {
    state.projects = state.projects.filter((obj) => obj.pk !== projectId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
