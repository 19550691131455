import "./assets/tailwind.css";
import "./registerServiceWorker";

import Rollbar from "rollbar";
import VCalendar from "v-calendar";
import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

// Set the Rollbar instance in the Vue prototype before creating the first Vue instance.
Vue.prototype.$rollbar = new Rollbar({
  enabled: process.env.NODE_ENV != "development",
  accessToken: process.env.VUE_APP_ROLLBAR_WORKFORCE_POST_CLIENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: process.env.NODE_ENV,
});

// Global error handler that preserves the app’s existing behavior while also reporting uncaught errors to Rollbar.
Vue.config.errorHandler = (err, vm) => {
  vm.$rollbar.error(err);
  throw err; // rethrow
};

Vue.use(VCalendar, {});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
