<template>
  <div class="relative">
    <input
      type="text"
      name="projectSearch"
      id="projectSearch"
      placeholder="Search a project"
      aria-label="Project Search"
      :value="searchValue"
      @input="$emit('set-search', $event.target.value)"
      class="pl-8 w-full py-2 bg-transparent border-0 border-b border-black border-opacity-10 placeholder-black placeholder-opacity-20 focus:ring-0 focus:border-opacity-100 focus:border-black transition duration-150"
    />
    <div
      class="icon-input-projects absolute inset-y-0 left-0 flex items-center pointer-events-none text-black opacity-25 transition-opacity duration-150"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 20" class="h-6">
        <g
          transform="translate(1 1)"
          stroke="currentColor"
          fill="none"
          fill-rule="evenodd"
        >
          <circle cx="7" cy="7" r="7" />
          <path
            stroke-linecap="square"
            d="M12 12.3333333l5.6666667 5.3333334"
          />
        </g>
      </svg>
    </div>
    <button
      v-show="searchValue.length > 0"
      @click="$emit('set-search', '')"
      class="absolute inset-y-0 px-2 -mr-3 right-0 flex items-center text-black opacity-25 hover:opacity-75 transition-opacity duration-150"
    >
      <svg
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        viewBox="0 0 24 24"
        stroke="currentColor"
        class="h-6"
      >
        <path d="M6 18L18 6M6 6l12 12"></path>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  props: {
    searchValue: {
      type: String,
    },
  },
};
</script>

<style scoped>
input:focus + .icon-input-projects {
  opacity: 1;
}
</style>
