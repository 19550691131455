import api from "@/services/api";

export default {
  fetchWorkforceRecords() {
    return api.get(`workforcerecords/`).then((response) => response.data);
  },
  fetchWorkforceRecord(workforcerecordId) {
    return api
      .get(`workforcerecords/${workforcerecordId}/`)
      .then((response) => response.data);
  },
  fetchWorkforceRecordsbyParams(params) {
    return api
      .get(`workforcerecords/`, { params })
      .then((response) => response.data);
  },
  postWorkforceRecord(payload) {
    return api
      .post(`workforcerecords/`, payload)
      .then((response) => response.data);
  },
  deleteWorkforceRecord(workforcerecordId) {
    return api
      .delete(`workforcerecords/${workforcerecordId}/`)
      .then((response) => response.data);
  },
};
