import api from "@/services/api";

export default {
  fetchGlobalHolidays() {
    return api.get(`globalholidays/`).then((response) => response.data);
  },
  fetchGlobalHoliday(globalHolidayId) {
    return api
      .get(`globalholidays/${globalHolidayId}/`)
      .then((response) => response.data);
  },
  fetchGlobalHolidaysbyParams(params) {
    return api
      .get(`globalholidays/`, { params })
      .then((response) => response.data);
  },
};
