import api from "@/services/api";

export default {
  fetchActiveStaffProjects() {
    return api.get(`activestaffprojects/`).then((response) => response.data);
  },
  fetchActiveStaffProject(activestaffprojectId) {
    return api
      .get(`activestaffprojects/${activestaffprojectId}/`)
      .then((response) => response.data);
  },
  fetchActiveStaffProjectsbyParams(params) {
    return api
      .get(`activestaffprojects/`, { params })
      .then((response) => response.data);
  },
  postActiveStaffProject(payload) {
    return api
      .post(`activestaffprojects/`, payload)
      .then((response) => response);
  },
  deleteActiveStaffProject(activestaffprojectId) {
    return api
      .delete(`activestaffprojects/${activestaffprojectId}/`)
      .then((response) => response.data);
  },
};
