import staffService from "../../services/staffService";

const state = {
  staff: {},
  currentStaff: {},
};

const getters = {
  staff: (state) => {
    return state.staff;
  },
};

const actions = {
  async getCurrentStaff({ commit }) {
    try {
      const response = await staffService.fetchCurrentStaff();
      const data = response.staff;
      commit("setCurrentStaff", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getStaff({ commit }) {
    try {
      const response = await staffService.fetchStaff();
      const data = response.results;
      commit("setStaff", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getStaffbyParams({ commit }, params) {
    try {
      const response = await staffService.fetchStaffbyParams(params);
      const data = response.results;
      commit("setStaff", data);
    } catch (error) {
      console.error(error);
    }
  },
  async addStaff({ commit }, staff) {
    try {
      const response = await staffService.postStaff(staff);
      commit("addStaff", response);
    } catch (error) {
      console.error(error);
    }
  },
  async deleteStaff({ commit }, staffId) {
    try {
      const response = await staffService.deleteStaff(staffId);
      commit("deleteStaff", response);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setStaff(state, staff) {
    state.staff = staff;
  },
  setCurrentStaff(state, currentStaff) {
    state.currentStaff = currentStaff;
  },
  addStaff(state, staff) {
    state.staff.push(staff);
  },
  deleteStaff(state, staffId) {
    state.staff = state.staff.filter((obj) => obj.pk !== staffId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
