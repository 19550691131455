import Vue from "vue";
import Vuex from "vuex";

import absences from "./modules/absences";
import activestaffprojects from "./modules/activestaffprojects";
// Api Related
import auth from "./modules/auth";
// Not related to API, Frontend State
import calendar from "./modules/calendar";
import departments from "./modules/departments";
import globalholidays from "./modules/globalholidays";
import holidays from "./modules/holidays";
import kpis from "./modules/kpis";
import projects from "./modules/projects";
import staff from "./modules/staff";
import users from "./modules/users";
import workforcerecords from "./modules/workforcerecords";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    departments,
    staff,
    users,
    projects,
    activestaffprojects,
    workforcerecords,
    globalholidays,
    holidays,
    absences,
    kpis,
    calendar,
  },
});
