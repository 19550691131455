<template>
  <div class="inline-block">
    <button
      @click="$emit('send-workforce-records')"
      class="px-2 w-32 py-4 bg-black text-white flex justify-center items-center rounded-full shadow-md focus:outline-none ripple"
    >
      <span>{{ totalHours }} h</span>
      <svg
        v-show="
          this.dateRecords.some(
            (record) =>
              record.updated === true &&
              (record.id != undefined || record.hours > 0)
          )
        "
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 29 23"
        class="h-5 ml-4 transform"
      >
        <g fill="none" fill-rule="evenodd" stroke="#FFF">
          <path d="M1.26 1L5.1 11.5 1.26 22l27-10.5z" />
          <path stroke-linecap="square" d="M5.18 11.43h22.2" />
        </g>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    totalHours: Number,
    dateRecords: Array,
  },
};
</script>

<style scoped>
/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #252525 radial-gradient(circle, transparent 1%, #272727 1%)
    center/15000%;
}
.ripple:active {
  background-color: #444444;
  background-size: 100%;
  transition: background 0s;
}
</style>
