<template>
  <div class="min-h-full">
    <PageHeader>
      <div class="flex items-center">
        <router-link
          to="home"
          class="px-2 -ml-2 py-1 inline-block focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </router-link>
      </div>

      <span class="font-medium"> My profile </span>

      <!-- <div class="flex items-center justify-end">
        <router-link
          to="projects"
          class="opacity-75 text-right focus:outline-none py-1 px-2 -mr-2"
        >
          Projects
        </router-link>
      </div> -->
    </PageHeader>

    <div class="md:flex">
      <div
        class="md:max-w-xl md:flex-1 w-full height-width-full relative bg-brand-gray-100"
      >
        <img
          v-if="this.currentStaff.userImg"
          class="w-full h-auto object-cover absolute inset-0"
          :src="this.currentStaff.userImg"
          alt=""
          srcset=""
        />
      </div>

      <div class="md:flex-1">
        <div class="px-4 py-4 bg-brand-gray-100 font-light">
          <div>
            <div
              v-show="loading"
              class="skeleton-title w-40 bg-black bg-opacity-25"
            ></div>
            <h2 class="text-3xl" v-show="!loading">
              {{ currentStaff.shortName }}
            </h2>
          </div>
          <div>
            <div
              v-show="loading"
              class="skeleton-p w-32 bg-black bg-opacity-25"
            ></div>
            <p v-show="!loading" class="text-sm">{{ currentStaff.position }}</p>
          </div>
        </div>

        <div>
          <ul class="border-t border-brand-gray-200">
            <li>
              <router-link
                to="holidays"
                class="px-4 py-4 flex justify-between w-full border-b border-brand-gray-200 font-light"
              >
                <span>Holidays</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="-mr-2"
                >
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              </router-link>
            </li>
            <li>
              <router-link
                to="absences"
                class="px-4 py-4 flex justify-between w-full border-b border-brand-gray-200 font-light"
              >
                <span>Absences</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="-mr-2"
                >
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              </router-link>
            </li>
            <li>
              <router-link
                to="password"
                class="px-4 py-4 flex justify-between w-full border-b border-brand-gray-200 font-light"
              >
                <span>Password</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="-mr-2"
                >
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              </router-link>
            </li>
            <li>
              <button
                @click="logout"
                class="px-4 py-4 flex justify-between w-full border-b border-brand-gray-200 font-light"
              >
                <span>Log out</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="-mr-2"
                >
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import PageHeader from "@/components/PageHeader";

export default {
  components: {
    PageHeader,
  },
  computed: {
    ...mapState("staff", ["currentStaff"]),
  },
  data() {
    return {
      loading: true,
    };
  },
  async created() {
    if (Object.keys(this.currentStaff).length === 0) {
      await this.getCurrentStaff();
    }
    this.loading = false;
  },
  methods: {
    ...mapActions("staff", ["getCurrentStaff"]),
    logout() {
      this.$store.dispatch("auth/logout").then(() => {
        // this.$router.push("login");
        window.location.replace("/login");
      });
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 767px) {
  .height-width-full {
    padding-top: 100%;
  }
}

.skeleton-title {
  height: 1.875rem;
  margin-top: 0.65rem;
  margin-bottom: 0.35rem;
}
.skeleton-p {
  height: 0.875rem;
  margin-top: 0.55rem;
  margin-bottom: 0.15rem;
}
</style>
