<template>
  <ul class="px-3 pt-5 pb-3 flex overflow-x-auto flex-shrink-0">
    <li
      class="flex flex-col items-center pr-3"
      v-for="(day, index) in dateRangesWithAttr"
      :key="index"
      :data-date="day.date"
    >
      <span class="text-sm">
        {{ format(new Date(day.date), "eee") }}
      </span>

      <div class="relative">
        <button
          @click="$emit('select-date', day.date)"
          class="mt-1 text-black h-12 w-12 flex justify-center rounded-full items-center outline-none focus:outline-none"
          v-bind:class="classCalendarItem(day)"
        >
          {{ format(new Date(day.date), "dd") }}
        </button>
        <ul class="pointer-events-none">
          <li v-for="(attr, i) in day.attributes" :key="i">
            <span
              class="absolute top-0 right-0 z-10 text-sm mt-1 mr-1"
              v-if="
                attr && attr.highlight && attr.highlight.class === 'dot-red'
              "
            >
              🔥
            </span>
            <span
              class="absolute top-0 right-0 z-10 text-sm mt-1 mr-1"
              v-if="attr && attr.dot === 'green'"
            >
              🤢
            </span>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
import { format, isSameDay } from "date-fns";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapGetters("calendar", ["dateRangesWithAttr"]),
    ...mapState("calendar", ["selectedDate"]),
  },
  methods: {
    classCalendarItem: function (day) {
      if (
        day.attributes.length &&
        day.attributes.some(
          (item) =>
            item.highlight && item.highlight.class === "highlight-yellow"
        )
      ) {
        if (isSameDay(day.date, this.selectedDate)) {
          return "bg-yellow-600";
        } else {
          return "bg-yellow-400";
        }
      }
      if (
        day.attributes.length &&
        day.attributes.some(
          (item) =>
            item.highlight && item.highlight.class === "highlight-purple"
        )
      ) {
        if (isSameDay(day.date, this.selectedDate)) {
          return "bg-purple-600";
        } else {
          return "bg-purple-400";
        }
      }
      if (isSameDay(day.date, this.selectedDate)) {
        return "bg-black text-white";
      } else return;
    },
  },
};
</script>
