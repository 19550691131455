import axios from "axios";

export default axios.create({
  baseURL: process.env.VUE_APP_API_BACK_URL + "/api/v1/",
  timeout: 5000,
  headers: {
    // eslint-disable-next-line prettier/prettier
    "Content-Type": "application/json",
  },
});
