import api from "@/services/api";

export default {
  fetchStaff() {
    return api.get(`staff/`).then((response) => response.data);
  },
  fetchCurrentStaff() {
    return api.get(`currentstaff/`).then((response) => response.data);
  },
  fetchOneStaff(staffId) {
    return api.get(`staff/${staffId}/`).then((response) => response.data);
  },

  fetchStaffbyParams(params) {
    return api.get(`staff/`, { params }).then((response) => response.data);
  },
  postStaff(payload) {
    return api.post(`staff/`, payload).then((response) => response.data);
  },
  deleteStaff(staffId) {
    return api.delete(`staff/${staffId}/`).then((response) => response.data);
  },
  patchStaff(staffId, payload) {
    return api
      .patch(`staff/${staffId}/`, payload)
      .then((response) => response.data);
  },
};
