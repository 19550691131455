<template>
  <li class="outline-card relative h-16 bg-black" v-if="isShowing">
    <div
      class="absolute right-0 top-0 bottom-0 h-full text-white flex items-center select-none"
    >
      <span class="mr-8">archive</span>
    </div>
    <div
      ref="interactElement"
      class="px-4 py-2 h-full bg-white flex items-center justify-between absolute left-0 right-0 draggable"
      :class="{
        'bg-brand-gray-100': workforcerecord.hours > 0,
        isAnimating: isInteractAnimating,
      }"
      :style="{ transform: transformString }"
    >
      <span class="font-light">{{
        activeproject.projectData.projectName
      }}</span>

      <div class="flex items-center space-x-2">
        <button
          @click.stop="decreaseHours()"
          class="h-10 w-10 rounded-full shadow flex justify-center items-center bg-white focus:outline-none border border-brand-gray-200 font-light"
        >
          -
        </button>
        <input
          class="input-num-text block w-12 px-0 rounded text-center px-0 bg-transparent border border-black placeholder-black border-opacity-0 placeholder-opacity-20 font-light focus:border-black focus:border-opacity-10 focus:ring-0 transition"
          type="number"
          name=""
          id=""
          :value="workforcerecord.hours"
          @input="inputRecordNumber($event.target.value)"
          lang="en"
          min="0"
          max="24"
          step="0.5"
          inputmode="numeric"
        />

        <button
          @click.stop="increaseHours()"
          class="h-10 w-10 rounded-full shadow flex justify-center items-center bg-white focus:outline-none border border-brand-gray-200 font-light"
        >
          +
        </button>
      </div>
    </div>
  </li>
</template>

<script>
import interact from "interactjs";
import { mapActions } from "vuex";

const INTERACT_X_THRESHOLD = 150;

export default {
  data() {
    return {
      isShowing: true,
      isInteractAnimating: true,
      isInteractDragged: null,
      interactPosition: 0,
    };
  },
  props: {
    activeproject: Object,
    workforcerecord: {
      type: Object,
      default: function () {
        return { hours: 0, project: this.activeproject.project };
      },
    },
  },
  created() {
    this.INTERACT_X_THRESHOLD = INTERACT_X_THRESHOLD;
    this.INTERACT_OUT_OF_SIGHT_X = window.innerWidth + 10;
  },
  methods: {
    ...mapActions("activestaffprojects", ["deleteActiveStaffProject"]),
    increaseHours() {
      if (this.workforcerecord.hours >= 24) return;
      this.$emit("modify-record", this.workforcerecord, "increase");
    },
    decreaseHours() {
      if (this.workforcerecord.hours <= 0) return;
      this.$emit("modify-record", this.workforcerecord, "decrease");
    },
    inputRecordNumber(num) {
      let value;
      if (!num.length) value = 0;
      else value = Number(num);
      this.$emit("modify-record", this.workforcerecord, "swap", value);
    },
    async deleteActiveProject(projectId) {
      await this.deleteActiveStaffProject(projectId);
    },
    interactSetPosition(x) {
      this.interactPosition = x;
      // console.log(x);
    },
    hideCard() {
      setTimeout(() => {
        this.isShowing = false;
        this.$emit("hide-card", this.card);
      }, 300);
    },
    interactUnsetElement() {
      interact(this.$refs.interactElement).unset();
      this.isInteractDragged = true;
    },
    resetCardPosition() {
      this.interactSetPosition({ x: 0, y: 0, rotation: 0 });
    },
  },
  computed: {
    transformString() {
      if (!this.isInteractAnimating || this.isInteractDragged) {
        return `translate3D(${this.interactPosition}px, 0px, 0)`;
      }
      return null;
    },
  },
  mounted() {
    const element = this.$refs.interactElement;
    interact(element).draggable({
      onstart: () => {
        this.isInteractAnimating = false;
      },
      onmove: (event) => {
        // console.log("Eventdy", event.dx);

        const x = this.interactPosition + event.dx;

        if (x <= 0) {
          this.interactSetPosition(x);
        }
      },
      onend: () => {
        this.isInteractAnimating = true;
        if (this.interactPosition < -this.INTERACT_X_THRESHOLD) {
          this.interactUnsetElement();
          this.interactSetPosition(-this.INTERACT_OUT_OF_SIGHT_X);
          console.log("DRAGGED OUT RIGHT");
          this.deleteActiveProject(this.activeproject.id);
          this.hideCard();
        } else {
          this.interactSetPosition(0);
        }
        console.log("END");
      },
      cursorChecker(action, interactable, element, interacting) {
        return interacting ? "grabbing" : "grab";
      },
    });
  },
  beforeDestroy() {
    interact(this.$refs.interactElement).unset();
  },
};
</script>

<style scoped>
.outline-card {
  box-shadow: 0 0 0 1px rgb(233, 233, 233);
}
/* Chrome, Safari, Edge, Opera */
.input-num-text::-webkit-outer-spin-button,
.input-num-text::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"].input-num-text {
  -moz-appearance: textfield;
}

.draggable {
  touch-action: pan-y;
  user-select: none;
}

.draggable.isAnimating {
  transition: all 0.25s ease-in-out;
}
</style>
