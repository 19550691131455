<template>
  <div
    class="transition duration-500 h-full"
    :class="{
      'bg-welcome-yellow': currentSlide === 0,
      'bg-welcome-blue': currentSlide === 1,
      'bg-welcome-red': currentSlide === 2,
      'bg-welcome-purple': currentSlide === 3,
      'bg-black': currentSlide === 4,
    }"
  >
    <div
      class="absolute top-0 z-10 text-center w-full flex justify-center px-4 py-6 border-b border-black border-opacity-25"
      :class="{ 'border-white': currentSlide === 1 || currentSlide === 3 }"
    >
      <span :class="{ 'text-white': currentSlide === 1 || currentSlide === 3 }">
        DDS WORKFORCE
      </span>
    </div>
    <flickity
      ref="flickity"
      :options="flickityOptions"
      @init="registerFlickityEvents()"
    >
      <div
        class="carousel-cell text-center font-light flex items-center justify-center"
      >
        <div class="px-10 pb-4 max-w-3xl">
          <h2 class="text-xl">
            Welcome to the ultimate time managing tool, the one and only
            Workforce!
          </h2>
          <h3 class="mt-6 text-5xl leading-none">👋🏼</h3>
          <p class="mt-6">
            This is a daily journal in which you will write the time that you
            invest in every project you’re working on once a day, like a diary
            of your day at DDS. It’s super useful for the managers, because that
            way they can see the evolution of the projects, they can elaborate
            budgets and plan ahead.
          </p>
          <p class="mt-6">
            You will be able to activate and turn off the projects you’re
            participating in currently; no worries, every time a new project
            arrives, you will receive a notification with an explanation not to
            get lost.
          </p>
        </div>
      </div>
      <div class="carousel-cell text-center flex justify-center items-center">
        <div class="px-10 pb-4 max-w-3xl mx-auto font-light text-white">
          <div class="square-image inline-block rounded-full overflow-hidden">
            <img
              class="w-full h-full object-cover"
              src="https://images.unsplash.com/photo-1585128903994-9788298932a6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
              alt=""
              srcset=""
            />
          </div>
          <p class="mt-12">
            Usually there are two types of projects: CLIENT and INTERNAL (they
            are also briefly explained so you know where to put those hours
            spent preparing conferences or organizing the workshop).
          </p>
        </div>
      </div>
      <div class="carousel-cell text-center flex justify-center items-center">
        <div class="px-10 pb-4 max-w-3xl mx-auto font-light">
          <div class="square-image inline-block rounded-full overflow-hidden">
            <img
              class="w-full h-full object-cover"
              src="https://images.unsplash.com/photo-1585128903994-9788298932a6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
              alt=""
              srcset=""
            />
          </div>
          <p class="mt-10">
            Also, you will be able to access to your own personal profile page,
            in which you will have your holiday schedule and you can request
            those much-needed days off. As we love data, you will as well find a
            report of your tasks and see where you’ve been creating magic
            lately.
          </p>
        </div>
      </div>
      <div class="carousel-cell text-center flex justify-center items-center">
        <div class="px-10 pb-4 max-w-3xl mx-auto text-white font-light">
          <div class="square-image inline-block rounded-full overflow-hidden">
            <img
              class="w-full h-full object-cover"
              src="https://images.unsplash.com/photo-1585128903994-9788298932a6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80"
              alt=""
              srcset=""
            />
          </div>
          <div class="">
            <p class="mt-10">
              Hope you like this tool we crafted to make life easier at DDS; if
              you have any doubts, ask the operations teams lead by Miquel, who
              will be happy to help.
            </p>
            <p class="mt-6">Proud to have you in the family!</p>
          </div>
        </div>
      </div>
      <div class="carousel-cell text-center flex justify-center items-center">
        <div class="px-10 pb-10 max-w-3xl mx-auto font-light text-white">
          <p class="text-2xl">Ready?</p>
          <router-link
            to="login"
            class="mt-4 inline-block w-56 px-2 py-3 rounded-full bg-white text-black"
            >Start</router-link
          >
        </div>
      </div>
    </flickity>
    <div class="bottom-dots fixed bottom-0 flex w-full justify-center">
      <ul class="flex">
        <li v-for="(n, i) in 4" :key="i">
          <button class="px-2 py-2" @click="$refs.flickity.select(i)">
            <div
              class="w-10 h-1 bg-opacity-25 bg-black"
              :class="{
                'bg-opacity-100': i === currentSlide,
                'bg-white': currentSlide === 1 || currentSlide === 3,
              }"
            ></div>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Flickity from "vue-flickity";

export default {
  components: {
    Flickity,
  },
  data() {
    return {
      currentSlide: 0,
      flickityOptions: {
        prevNextButtons: false,
        wrapAround: false,
        pageDots: false,

        // any options from Flickity can be used
      },
    };
  },
  created() {
    window.localStorage.setItem("isNotFirstLoad", true);
  },
  methods: {
    registerFlickityEvents() {
      this.$refs.flickity.on("change", this.changeSlide);
    },
    changeSlide(index) {
      this.currentSlide = index;
    },
  },
};
</script>

<style scoped>
.bg-welcome-yellow {
  background: #ffe82d;
}
.bg-welcome-blue {
  background: #0f0ba5;
}
.bg-welcome-red {
  background: #fbb0af;
}
.bg-welcome-purple {
  background: #6f47d8;
}

.text-welcome-purple {
  color: #6f47d8;
}

.square-image {
  width: 70vw;
  height: 70vw;
  max-width: 350px;
  max-height: 350px;
}
.bottom-dots {
  bottom: 5%;
}
</style>

<style>
.carousel-cell {
  width: 100%; /* full width */
  height: 100%; /* height of carousel */
}
.flickity-enabled {
  height: 100%;
}
</style>
