import userService from "../../services/userService";

const state = () => ({
  currentUser: {},
});

const getters = {};

const actions = {
  async getUserByUrl({ commit }, url) {
    try {
      const response = await userService.fetchUserByUrl(url);
      const data = response;
      commit("setUser", data);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setUser(state, user) {
    state.currentUser = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
