import api from "@/services/api";

export default {
  fetchDepartments() {
    return api.get(`departments/`).then((response) => response.data);
  },
  fetchDepartment(departmentId) {
    return api
      .get(`departments/${departmentId}/`)
      .then((response) => response.data);
  },
  postDepartment(payload) {
    return api.post(`departments/`, payload).then((response) => response.data);
  },
  deleteDepartment(departmentId) {
    return api
      .delete(`departments/${departmentId}/`)
      .then((response) => response.data);
  },
  patchDepartment(departmentId, payload) {
    return api
      .patch(`departments/${departmentId}/`, payload)
      .then((response) => response.data);
  },
};
