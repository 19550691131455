import api from "@/services/api";

export default {
  login(username, password) {
    return api.post("/auth/login/", { username, password });
  },
  logout() {
    return api.post("/auth/logout/", {});
  },
  createAccount(username, password1, password2, email) {
    return api.post("/registration/", {
      username,
      password1,
      password2,
      email,
    });
  },
  changeAccountPassword(new_password1, new_password2) {
    return api.post("/auth/password/change/", { new_password1, new_password2 });
  },
  sendAccountPasswordResetEmail(email) {
    return api.get(`/resetpassword/?email=${email}`);
  },
};
