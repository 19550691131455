/* eslint-disable no-unused-vars */
import workforceRecordService from "../../services/workforceRecordService";

const state = {
  workforcerecords: [],
};

const getters = {
  workforcerecords: (state) => {
    return state.workforcerecords;
  },
};

const actions = {
  async getWorkforceRecords({ commit }) {
    try {
      const response = await workforceRecordService.fetchWorkforceRecords();
      const data = response.results;
      commit("setWorkforceRecords", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getWorkforceRecordsbyParams({ commit }, params) {
    try {
      const response = await workforceRecordService.fetchWorkforceRecordsbyParams(
        params
      );
      const data = await response.results;
      commit("setWorkforceRecords", data);
    } catch (error) {
      console.error(error);
    }
  },
  async addOneWorkforceRecord({ commit }, workforcerecord) {
    try {
      const response = await workforceRecordService.postWorkforceRecord(
        workforcerecord
      );
      commit("addWorkforceRecord", response);
    } catch (error) {
      console.error(error);
    }
  },
  async addWorkforceRecords({ commit }, workforcerecords) {
    const promises = workforcerecords.map(async (item) => {
      return await workforceRecordService.postWorkforceRecord(item);
    });

    await Promise.all(promises)
      .then((result) => {
        console.log("DONE", result);
        commit("addWorkforceRecords", result);
      })
      .catch((error) => {
        console.log("ERROR PROMISES");
        console.error(error);
      });
  },
  async deleteOneWorkforceRecord({ commit }, workforcerecordId) {
    let response;
    try {
      response = await workforceRecordService.deleteWorkforceRecord(
        workforcerecordId
      );
      // commit("deleteWorkforceRecord", workforcerecordId);
      console.log("SUCESS DELETED VUEX", response);
    } catch (error) {
      console.log("ERROR");
      console.error(error);
    }
  },
  async deleteWorkforceRecords({ commit }, workforcerecordIds) {
    console.log("WE ARE DELETING", workforcerecordIds);
    const promises = workforcerecordIds.map(async (item) => {
      return await workforceRecordService.deleteWorkforceRecord(item);
    });

    await Promise.all(promises)
      .then(() => {
        console.log("DONE DELETING");
        // commit("deleteWorkforceRecords", workforcerecordIds);
      })
      .catch((error) => {
        console.log("ERROR PROMISES");
        console.error(error);
      });
  },
};

const mutations = {
  setWorkforceRecords(state, workforcerecords) {
    state.workforcerecords = workforcerecords;
  },
  addWorkforceRecord(state, workforcerecord) {
    state.workforcerecords.push(workforcerecord);
  },
  addWorkforceRecords(state, workforcerecords) {
    workforcerecords.forEach((wkfrecord) => {
      state.workforcerecords.push(wkfrecord);
    });
  },
  deleteWorkforceRecord(state, workforcerecordId) {
    state.workforcerecords = state.workforcerecords.filter(
      (obj) => obj.id !== workforcerecordId
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
