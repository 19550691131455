<template>
  <li
    class="outline-card relative h-16 bg-black transition-height duration-300 overflow-hidden"
    :class="{
      'h-64': isExpanded,
    }"
  >
    <div
      class="absolute right-0 top-0 bottom-0 h-full text-white flex items-center select-none"
    >
      <slot name="swipe-actions"></slot>
    </div>
    <div
      ref="interactElement"
      class="absolute px-4 py-3 left-0 right-0 h-full bg-white flex flex-col draggable hover:bg-brand-gray-100"
      :class="{
        isAnimating: isInteractAnimating,
      }"
      :style="{ transform: transformString }"
    >
      <div class="flex h-10 items-center justify-between">
        <slot name="main"></slot>
      </div>
      <div>
        <slot name="about" v-if="isExpanded"></slot>
      </div>
    </div>
  </li>
</template>

<script>
import interact from "interactjs";
import { mapState } from "vuex";

const INTERACT_X_THRESHOLD = 150;

export default {
  props: {
    project: Object,
    isExpanded: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isInteractAnimating: true,
      isInteractDragged: null,
      interactPosition: 0,
    };
  },
  created() {
    this.INTERACT_X_THRESHOLD = INTERACT_X_THRESHOLD;
    this.INTERACT_OUT_OF_SIGHT_X = window.innerWidth + 10;
  },
  computed: {
    ...mapState("staff", ["currentStaff", "staff"]),
    transformString() {
      if (!this.isInteractAnimating || this.isInteractDragged) {
        return `translate3D(${this.interactPosition}px, 0px, 0)`;
      }
      return null;
    },
  },
  mounted() {
    const element = this.$refs.interactElement;
    interact(element).draggable({
      onstart: () => {
        this.isInteractAnimating = false;
      },
      onmove: (event) => {
        const x = this.interactPosition + event.dx;
        if (x <= 0) {
          this.interactSetPosition(x);
        }
      },
      onend: () => {
        this.isInteractAnimating = true;
        if (this.interactPosition < -this.INTERACT_X_THRESHOLD) {
          this.interactUnsetElement();
          this.interactSetPosition(-this.INTERACT_OUT_OF_SIGHT_X);
          console.log("DRAGGED OUT RIGHT");
          // this.deleteActiveProject(this.activeproject.id);

          setTimeout(() => {
            this.isInteractDragged = false;
            this.interactSetPosition(0);
          }, 300);
          setTimeout(() => {
            this.$emit("toggle-staff-project-status", this.project);
          }, 400);

          // this.interactSetPosition(0);
        } else {
          this.interactSetPosition(0);
        }
        console.log("END");
      },
      cursorChecker(action, interactable, element, interacting) {
        return interacting ? "grabbing" : "grab";
      },
    });

    interact(element).on("tap", (event) => {
      if (event.target.id === "activationToggle") return;
      this.$emit("expand", this.project.project);
    });
  },
  beforeDestroy() {
    interact(this.$refs.interactElement).unset();
  },
  methods: {
    interactSetPosition(x) {
      this.interactPosition = x;
    },
    interactUnsetElement() {
      // interact(this.$refs.interactElement).unset();
      this.isInteractDragged = true;
    },
    resetCardPosition() {
      this.interactSetPosition({ x: 0, y: 0, rotation: 0 });
    },
  },
};
</script>

<style scoped>
.outline-card {
  box-shadow: 0 0 0 1px rgb(233, 233, 233);
}
.draggable {
  touch-action: pan-y;
  user-select: none;
}

.draggable.isAnimating {
  transition: all 0.25s ease-in-out;
}
.transition-height {
  transition-property: height;
}
</style>
