import holidayService from "../../services/holidayService";

const state = {
  holidays: [],
  holidayDaysLeft: "",
};

const getters = {
  holidays: (state) => {
    return state.holidays;
  },
};

const actions = {
  async getHolidays({ commit }) {
    try {
      const response = await holidayService.fetchHolidays();
      const data = response.results;
      commit("setHolidays", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getHoliday({ commit }, holidayId) {
    try {
      const response = await holidayService.fetchHoliday(holidayId);
      const data = response.results;
      commit("setHolidays", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getHolidaysbyParams({ commit }, params) {
    try {
      const response = await holidayService.fetchHolidaysbyParams(params);
      const data = response.results;
      commit("setHolidays", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getHolidayDaysLeft({ commit }) {
    try {
      const response = await holidayService.fetchHolidayDaysLeft();
      const data = response;
      commit("setHolidayDaysLeft", data);
    } catch (error) {
      console.error(error);
    }
  },
  async addHoliday({ commit }, holiday) {
    try {
      const response = await holidayService.postHoliday(holiday);
      commit("addHoliday", response);
    } catch (error) {
      console.error(error);
    }
  },
  async deleteHoliday({ commit }, holidayId) {
    try {
      const response = await holidayService.deleteHoliday(holidayId);
      commit("deleteHoliday", response);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setHolidays(state, holidays) {
    state.holidays = holidays;
  },
  setHolidayDaysLeft(state, holidaysLeft) {
    state.holidayDaysLeft = holidaysLeft;
  },
  addHoliday(state, holiday) {
    state.holidays.unshift(holiday);
  },
  deleteHoliday(state, holidayId) {
    state.holidays = state.holidays.filter((obj) => obj.pk !== holidayId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
