import { isSameDay, startOfDay } from "date-fns";

import calendarService from "../../services/calendarService.js";

const state = () => ({
  selectedDate: startOfDay(new Date()),
  dateRanges: [],
  calendarAttributes: [],
  unfilledDays: [],
});

const getters = {
  dateRangesWithAttr: (state) => {
    return state.dateRanges.map((date) => {
      return {
        date: date,
        attributes: state.calendarAttributes.filter((attr) =>
          attr.dates.some((dateAttr) => isSameDay(new Date(dateAttr), date))
        ),
      };
    });
  },
};

const actions = {
  // unfilledDays - Fires
  async getUnfilledDays({ commit }, { range, attributes }) {
    try {
      const response = await calendarService.fetchUnfilledDates({
        range,
        attributes,
      });
      commit("setUnfilledDays", response);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  selectNewDate(state, newDate) {
    state.selectedDate = newDate;
  },
  setDateRanges(state, dateRange) {
    state.dateRanges = dateRange;
  },
  setCalendarAttributes(state, attributes) {
    state.calendarAttributes = attributes;
  },
  setUnfilledDays(state, unfilledDays) {
    state.unfilledDays = unfilledDays;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
