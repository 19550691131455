import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Profile from "../views/Profile.vue";
import Projects from "../views/Projects.vue";
import Welcome from "../views/Welcome.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresUnAuth: true },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgotpassword" */ "../views/ForgotPassword.vue"
      ),
  },
  {
    path: "/projects",
    name: "Projects",
    meta: { requiresAuth: true },
    component: Projects,
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { requiresAuth: true },
    component: Profile,
  },
  {
    path: "/holidays",
    name: "Holidays",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "holidays" */ "../views/Holidays.vue"),
  },
  {
    path: "/absences",
    name: "Absences",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "absences" */ "../views/Absences.vue"),
  },
  {
    path: "/password",
    name: "Password",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "password" */ "../views/Password.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store.dispatch("auth/initialize").then(() => {
      if (!store.getters["auth/isAuthenticated"]) {
        const isNotFirstLoad = window.localStorage.getItem("isNotFirstLoad");
        if (isNotFirstLoad) {
          next({
            path: "/login",
          });
        } else next({ path: "/welcome" });
      } else {
        next();
      }
    });
  } else if (to.matched.some((record) => record.meta.requiresUnAuth)) {
    store.dispatch("auth/initialize").then(() => {
      if (store.getters["auth/isAuthenticated"]) {
        next({
          path: "/",
        });
      } else {
        next();
      }
    });
  } else next();
});

export default router;
