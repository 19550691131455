<template>
  <div class="text-center flex flex-col h-screen">
    <div class="header flex flex-shrink-0 justify-center items-center border-b">
      <router-link to="/login">
        <span class="uppercase text-sm font-semibold tracking-widest pt-1">
          Domestic Data Streamers
        </span>
      </router-link>
    </div>

    <ToastNotification :message="toastMessage"></ToastNotification>

    <div class="px-6 flex flex-col flex-1 justify-center items-center mb-12">
      <h1 class="text-3xl font-light">Workforce</h1>
      <h2 class="text-xl font-light">Login</h2>
      <ValidationObserver v-slot="{ handleSubmit }" class="w-full px-6 mt-20">
        <form
          class="flex flex-col"
          @submit.prevent="handleSubmit(submit)"
          novalidate
        >
          <div class="text-left">
            <ValidationProvider
              :rules="{ required: true, email: true }"
              v-slot="{ errors }"
            >
              <div>
                <input
                  id="email"
                  aria-label="Email"
                  name="Email"
                  type="email"
                  autocomplete="email"
                  class="mt-1 block w-full px-0 border-0 border-b border-black placeholder-black border-opacity-10 placeholder-opacity-20 py-1 font-light focus:border-black focus:border-opacity-100 focus:ring-0 transition"
                  placeholder="Email"
                  v-model="login.username"
                />
                <div
                  class="h-6 text-sm flex items-center text-red-500 font-light"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>

            <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
              <div class="mt-1">
                <input
                  id="password"
                  aria-label="Password"
                  name="Password"
                  type="password"
                  autocomplete="current-password"
                  class="mt-1 block w-full px-0 border-0 border-b border-black placeholder-black border-opacity-10 placeholder-opacity-20 py-1 font-light focus:border-black focus:border-opacity-100 focus:ring-0 transition"
                  placeholder="Password"
                  v-model="login.password"
                />
                <div
                  class="h-6 text-sm flex items-center text-red-500 font-light"
                >
                  <span>{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
          </div>

          <div class="mt-20">
            <button
              type="submit"
              class="w-56 py-3 rounded-full bg-black text-white outline-none focus:outline-none"
            >
              Go!
            </button>

            <div class="mt-4 text-sm">
              <router-link to="/forgot-password"
                >Forgot your password?</router-link
              >
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import { email, required } from "vee-validate/dist/rules";

import ToastNotification from "@/components/ToastNotification.vue";

setInteractionMode("eager");
extend("email", email);
extend("required", required);

export default {
  components: {
    ToastNotification,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      login: {
        username: "",
        password: "",
      },
      errorMsg: "",
      toastMessage: { type: "", content: "" },
    };
  },
  methods: {
    submit() {
      this.toastMessage = { type: "", content: "" };

      this.$store
        .dispatch("auth/login", this.login)
        .then(() => {
          if (!this.$store.state.auth.error) {
            this.$router.push("/");
          }
        })
        .catch((error) => {
          const errorData = error.response.data;
          const toastMessage = errorData[Object.keys(errorData)[0]][0];

          this.toastMessage.type = "error";

          this.toastMessage.content = toastMessage.length
            ? toastMessage
            : "There was a problem";
        });
    },
  },
};
</script>

<style scoped>
.header {
  height: 4rem;
}
</style>
