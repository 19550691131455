import globalHolidayService from "../../services/globalHolidayService";

const state = {
  globalHolidays: [],
};

const getters = {
  globalHolidays: (state) => {
    return state.globalHolidays;
  },
};

const actions = {
  async getGlobalHolidays({ commit }) {
    try {
      const response = await globalHolidayService.fetchGlobalHolidays();
      const data = response.results;
      commit("setGlobalHolidays", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getGlobalHoliday({ commit }, globalHolidayId) {
    try {
      const response = await globalHolidayService.fetchGlobalHoliday(
        globalHolidayId
      );
      const data = response.results;
      commit("setGlobalHolidays", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getGlobalHolidaysbyParams({ commit }, params) {
    try {
      const response = await globalHolidayService.fetchGlobalHolidaysbyParams(
        params
      );
      const data = response.results;
      commit("setGlobalHolidays", data);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setGlobalHolidays(state, globalHolidays) {
    state.globalHolidays = globalHolidays;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
