import api from "@/services/api";

export default {
  fetchUsers() {
    return api.get(`users/`).then((response) => response.data);
  },
  fetchUser(userId) {
    return api.get(`users/${userId}/`).then((response) => response.data);
  },
  fetchUserByUrl(url) {
    return api.get(url).then((response) => response.data);
  },
  patchUser(userId, payload) {
    return api
      .patch(`users/${userId}/`, payload)
      .then((response) => response.data);
  },
};
