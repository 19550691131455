<template>
  <transition
    enter-active-class="transition-all duration-200 ease-in-out"
    leave-active-class="transition-all duration-150 ease-in-out"
    enter-class="opacity-0 translate-y-2"
    enter-to-class="opacity-100 translate-y-0"
    leave-class="opacity-100 translate-y-0"
    leave-to-class="opacity-0 translate-y-2"
  >
    <div
      class="fixed z-50 bottom-0 mb-4 w-full text-center transform text-sm"
      v-if="message.content.length"
    >
      <div
        class="mx-4 px-4 py-2 inline-block rounded"
        :class="{
          'bg-red-100 text-red-500': message.type === 'error',
          'bg-green-100 text-green-500': message.type === 'success',
        }"
      >
        <span>{{ message.content }}</span>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: Object,
  },
};
</script>

<style></style>
