<template>
  <div class="flex flex-col h-full bg-brand-gray-100">
    <PageHeader>
      <div class="flex items-center">
        <router-link
          to="login"
          class="px-2 -ml-2 py-1 inline-block focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
        </router-link>
      </div>

      <span class="font-medium"> Projects </span>

      <!-- <div class="flex items-center justify-end">
        <router-link
          to="projects"
          class="opacity-75 text-right focus:outline-none py-1 px-2 -mr-2"
        >
          Archive
        </router-link>
      </div> -->
    </PageHeader>
    <nav class="px-4 w-full mt-4 max-w-xl mx-auto">
      <ul class="flex text-center space-x-4">
        <li class="flex-1">
          <button
            @click="(currentTab = 'clients'), (expandedCard = null)"
            class="px-2 py-4 w-full border border-black bg-black bg-opacity-0 text-black rounded-full outline-none focus:outline-none"
            :class="{ 'bg-opacity-100 text-white': currentTab === 'clients' }"
          >
            Clients
          </button>
        </li>
        <li class="flex-1">
          <button
            @click="(currentTab = 'internal'), (expandedCard = null)"
            class="px-2 py-4 w-full border border-black bg-black bg-opacity-0 rounded-full outline-none focus:outline-none"
            :class="{ 'bg-opacity-100 text-white': currentTab === 'internal' }"
          >
            Internal
          </button>
        </li>
      </ul>
    </nav>

    <div class="mt-4 px-4">
      <SearchBar :searchValue="search" @set-search="setSearch"></SearchBar>
    </div>

    <div class="mt-3">
      <ProjectsList
        :projects="searchFilteredProjects"
        :search="search"
      ></ProjectsList>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import PageHeader from "@/components/PageHeader";
import ProjectsList from "@/components/ProjectsList";
import SearchBar from "@/components/SearchBar";

export default {
  components: {
    PageHeader,
    ProjectsList,
    SearchBar,
  },
  data() {
    return {
      currentTab: "clients", // clients or internal
      search: "",
    };
  },
  computed: {
    ...mapGetters("projects", [
      "projectsByStaffActiveStatus",
      "internalProjectsByStaffActiveStatus",
      "clientsProjectsByStaffActiveStatus",
    ]),
    ...mapState("staff", ["currentStaff", "staff"]),
    ...mapState("activestaffprojects", ["activestaffprojects"]),
    ...mapState("projects", ["projects"]),

    projectsForActiveTab() {
      let array = [];
      if (this.currentTab === "clients") {
        array = this.clientsProjectsByStaffActiveStatus;
      } else if (this.currentTab === "internal") {
        array = this.internalProjectsByStaffActiveStatus;
      }
      return array;
    },
    searchFilteredProjects() {
      return this.projectsForActiveTab.filter((project) =>
        project.project.projectName
          .toLowerCase()
          .includes(this.search.toLowerCase())
      );
    },
  },

  async created() {
    if (Object.keys(this.currentStaff).length === 0) {
      await this.getCurrentStaff();
    }

    if (this.projects.length === 0) {
      await this.getProjectsbyParams({
        active: true,
      });
    }

    await this.getActiveStaffProjectsbyParams({
      staff: this.currentStaff.id,
    });
  },
  methods: {
    ...mapActions("staff", ["getCurrentStaff"]),
    ...mapActions("projects", ["getProjectsbyParams", "getProjectStats"]),
    ...mapActions("activestaffprojects", ["getActiveStaffProjectsbyParams"]),
    setSearch(value) {
      this.search = value;
    },
  },
};
</script>
