import api from "@/services/api";

export default {
  fetchProjects() {
    return api.get(`projects/`).then((response) => response.data);
  },
  fetchProject(projectId) {
    return api.get(`projects/${projectId}/`).then((response) => response.data);
  },
  fetchProjectsbyParams(params) {
    return api.get(`projects/`, { params }).then((response) => response.data);
  },
  fetchProjectStats(projectId) {
    return api
      .get(`projectstats/?project_id=${projectId}`)
      .then((response) => response.data);
  },
  postProject(payload) {
    return api.post(`projects/`, payload).then((response) => response.data);
  },
  deleteProject(projectId) {
    return api
      .delete(`projects/${projectId}/`)
      .then((response) => response.data);
  },
};
