import api from "@/services/api";

export default {
  fetchHolidays() {
    return api.get(`holidays/`).then((response) => response.data);
  },
  fetchHolidayDaysLeft() {
    return api.get(`holidaysleft/`).then((response) => response.data);
  },
  fetchHoliday(holidayId) {
    return api.get(`holidays/${holidayId}/`).then((response) => response.data);
  },
  fetchHolidaysbyParams(params) {
    return api.get(`holidays/`, { params }).then((response) => response.data);
  },
  postHoliday(payload) {
    return api.post(`holidays/`, payload).then((response) => response.data);
  },
  deleteHoliday(holidayId) {
    return api
      .delete(`holidays/${holidayId}/`)
      .then((response) => response.data);
  },
  sendHolidaysRequestEmail() {
    return api.get(`sendholidaysrequest/`).then((response) => response.data);
  },
};
