<template>
  <div
    role="dialog"
    class="fixed modal-top inset-0 bg-red-300 w-full h-full text-center"
    aria-modal="true"
    aria-labelledby="dialog1Title"
  >
    <div class="mt-10">
      <header>
        <h2 class="text-2xl" id="dialog1Title">Emotional Index</h2>
      </header>
    </div>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form class="mt-10" novalidate @submit.prevent="handleSubmit(sendKpis)">
        <ul
          class="divide-y border-t border-b border-black border-opacity-25 divide-opacity-25 divide-black"
        >
          <li
            class="pt-5 pb-1"
            v-for="indexKPI in kpiValues"
            :key="indexKPI.url"
          >
            <fieldset class="inline-block">
              <legend class="mx-auto">{{ indexKPI.name }}</legend>
              <ValidationProvider
                class=""
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <ol class="flex space-x-2 mt-2">
                  <li v-for="i in 5" :key="i" class="">
                    <label class="c-custom-checkbox relative flex items-center">
                      <input
                        type="radio"
                        :id="`${indexKPI.name}_${i}`"
                        :name="indexKPI.name"
                        :value="i.toFixed(1)"
                        v-model="indexKPI.points"
                        class="absolute opacity-0"
                        required
                      />
                      <svg
                        viewBox="0 0 100 100"
                        aria-hidden="true"
                        focusable="false"
                      >
                        <!-- The background -->
                        <circle
                          cx="50"
                          cy="50"
                          r="40"
                          class="cb-bg"
                          stroke="currentColor"
                          fill="none"
                          stroke-width="3"
                        />
                      </svg>
                      <span class="absolute flex items-center justify-center">
                        {{ i }}
                      </span>
                    </label>
                  </li>
                </ol>
                <div class="h-6 text-sm text-red-700">
                  <span>{{ errors[0] }}</span>
                </div>
              </ValidationProvider>
            </fieldset>
          </li>
        </ul>
        <div class="mt-10">
          <button
            type="submit"
            class="px-12 py-3 border-2 border-black rounded-full"
          >
            Send
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { formatISO } from "date-fns";
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapActions, mapState } from "vuex";

setInteractionMode("eager");
extend("required", required);

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      kpiValues: [],
      selectedDateKpiRecords: [],
    };
  },
  computed: {
    ...mapState("staff", ["currentStaff"]),
    ...mapState("calendar", ["selectedDate"]),
    ...mapState("kpis", ["kpilist", "kpirecords"]),
  },
  async created() {
    if (!this.kpilist.length) {
      console.log("%cGETTING LIST", "color: white; background: red;");
      await this.getKpiList();
    }

    const currentDate = formatISO(this.selectedDate, {
      representation: "date",
    });

    await this.getKpiRecordsbyParams({
      staff: this.currentStaff.id,
      date: currentDate,
    });

    this.setKpiRecordsForDate();
  },
  methods: {
    ...mapActions("kpis", [
      "getKpiList",
      "getKpiRecordsbyParams",
      "addKpiRecords",
      "deleteKpiRecords",
    ]),

    setKpiRecordsForDate() {
      this.kpiValues = this.kpilist.map((item) => {
        let points = "";
        if (this.kpirecords.length) {
          const found = this.kpirecords.find(
            (record) => record.kpi === item.url
          );

          if (found) points = found.points;
        }
        return {
          name: item.kpiName,
          kpi: item.url,
          points: points,
        };
      });
    },

    async sendKpis() {
      const selectedDate = formatISO(this.selectedDate, {
        representation: "date",
      });

      const deleteKpis = [];
      if (this.kpirecords.length)
        this.kpirecords.forEach((record) => {
          deleteKpis.push(record.id);
        });

      console.log("TO DELETE", deleteKpis);

      await this.deleteKpiRecords(deleteKpis);

      const data = this.kpiValues.map((item) => {
        const obj = item;
        obj.date = selectedDate;
        obj.staff = this.currentStaff.url;
        return obj;
      });

      console.log("ADD", data);

      await this.addKpiRecords(data);

      this.$emit("close-modal");
    },
  },
};
</script>

<style scoped>
.modal-top {
  z-index: 10000;
}
.c-custom-checkbox {
  --checked-state-bg-color: #fa2e50;
  --checked-state-check-color: #fff;
  --outline-color: var(--checked-state-bg-color);
  --outline-offset: 2px;
}
.c-custom-checkbox input[type="radio"] {
  /* set same dimensions as the SVG */
  width: 4em;
  height: 4em;
}
.c-custom-checkbox span {
  /* set same dimensions as the SVG */
  width: 4em;
  height: 4em;
}
.c-custom-checkbox svg {
  /* set SVG dimensions in ems;
	 i.e. relative to the font size so that it scales with the size of the font. */
  width: 4em;
  height: 4em;
  /* apply a transition to the elements inside the svg */
}
.c-custom-checkbox svg * {
  transition: all 0.1s linear;
}
.c-custom-checkbox input[type="radio"]:checked + svg .cb-bg {
  fill: var(--checked-state-bg-color);
  stroke: var(--checked-state-bg-color);
}
/* .c-custom-checkbox input[type="radio"]:focus + svg {
  outline: 3px solid var(--outline-color);
  outline-offset: var(--outline-offset);
} */
.c-custom-checkbox input[type="radio"]:focus:not(:focus-visible) + svg {
  outline: none;
}
</style>
