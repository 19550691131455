import activeStaffProjectService from "../../services/activeStaffProjectService";

const state = {
  activestaffprojects: [],
};

const getters = {
  activestaffprojects: (state) => {
    return state.activestaffprojects;
  },
};

const actions = {
  async getActiveStaffProjects({ commit }) {
    try {
      const response = await activeStaffProjectService.fetchActiveStaffProjects();
      const data = response.results;
      commit("setActiveStaffProjects", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getActiveStaffProjectsbyParams({ commit }, params) {
    try {
      const response = await activeStaffProjectService.fetchActiveStaffProjectsbyParams(
        params
      );
      const data = await response.results;
      commit("setActiveStaffProjects", data);
    } catch (error) {
      console.error(error);
    }
  },
  async addActiveStaffProject({ commit }, activestaffproject) {
    try {
      const response = await activeStaffProjectService.postActiveStaffProject(
        activestaffproject
      );
      commit("addActiveStaffProject", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async deleteActiveStaffProject({ commit }, staffId) {
    try {
      await activeStaffProjectService.deleteActiveStaffProject(staffId);
      commit("deleteActiveStaffProject", staffId);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setActiveStaffProjects(state, activestaffprojects) {
    state.activestaffprojects = activestaffprojects;
  },
  addActiveStaffProject(state, activestaffproject) {
    console.log("NEW PROJECT", activestaffproject);
    const newProject = activestaffproject;
    newProject.project = newProject.projectData.url;
    state.activestaffprojects.push(newProject);
  },
  deleteActiveStaffProject(state, staffId) {
    state.activestaffprojects = state.activestaffprojects.filter(
      (obj) => obj.id !== staffId
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
