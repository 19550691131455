import api from "@/services/api";

export default {
  fetchKpiRecord(kpiRecordId) {
    return api
      .get(`kpirecords/${kpiRecordId}/`)
      .then((response) => response.data);
  },
  fetchKpiRecordsbyParams(params) {
    return api.get(`kpirecords/`, { params }).then((response) => response.data);
  },
  postKpiRecord(payload) {
    return api.post(`kpirecords/`, payload).then((response) => response.data);
  },
  deleteKpiRecord(kpiRecordId) {
    return api
      .delete(`kpirecords/${kpiRecordId}/`)
      .then((response) => response.data);
  },
};
