import absenceService from "../../services/absenceService";

const state = {
  absences: [],
};

const getters = {
  absences: (state) => {
    return state.absences;
  },
};

const actions = {
  async getAbsences({ commit }) {
    try {
      const response = await absenceService.fetchAbsences();
      const data = response.results;
      commit("setAbsences", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getAbsence({ commit }, absenceId) {
    try {
      const response = await absenceService.fetchAbsence(absenceId);
      const data = response.results;
      commit("setAbsences", data);
    } catch (error) {
      console.error(error);
    }
  },
  async getAbsencesbyParams({ commit }, params) {
    try {
      const response = await absenceService.fetchAbsencesbyParams(params);
      const data = response.results;
      commit("setAbsences", data);
    } catch (error) {
      console.error(error);
    }
  },
  async addAbsence({ commit }, absence) {
    try {
      const response = await absenceService.postAbsence(absence);
      commit("addAbsence", response);
    } catch (error) {
      console.error(error);
    }
  },
  async deleteAbsence({ commit }, absenceId) {
    try {
      const response = await absenceService.deleteAbsence(absenceId);
      commit("deleteAbsence", response);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setAbsences(state, absences) {
    state.absences = absences;
  },
  addAbsence(state, absence) {
    state.absences.unshift(absence);
  },
  deleteAbsence(state, absenceId) {
    state.absences = state.absences.filter((obj) => obj.pk !== absenceId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
