import departmentService from "../../services/departmentService";

const state = {
  departments: [],
};

const getters = {
  departments: (state) => {
    return state.departments;
  },
};

const actions = {
  async getDepartments({ commit }) {
    try {
      const response = await departmentService.fetchDepartments();
      const data = response.results;
      commit("setDepartments", data);
    } catch (error) {
      console.error(error);
    }
  },
  async addDepartment({ commit }, department) {
    try {
      const response = await departmentService.postDepartment(department);
      commit("addDepartment", response);
    } catch (error) {
      console.error(error);
    }
  },
  async deleteDepartment({ commit }, departmentId) {
    try {
      const response = await departmentService.deleteDepartment(departmentId);
      commit("deleteDepartment", response);
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  setDepartments(state, departments) {
    state.departments = departments;
  },
  addDepartment(state, department) {
    state.departments.push(department);
  },
  deleteDepartment(state, departmentId) {
    state.departments = state.departments.filter(
      (obj) => obj.pk !== departmentId
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
