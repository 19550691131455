<template>
  <ul class="overflow-x-hidden py-1">
    <ProjectCardGeneral
      :class="{
        'bg-green-400': !project.staffStatus.staffProjectActive,
      }"
      v-for="project in projects"
      :key="project.project.id"
      :project="project"
      :is-expanded="project.project.id === expandedCard"
      @expand="setExpanded"
      @toggle-staff-project-status="toggleStaffProjectStatus"
    >
      <template slot="swipe-actions">
        <span class="mr-8" v-if="!project.staffStatus.staffProjectActive">
          activate
        </span>
        <span class="mr-8" v-else-if="project.staffStatus.staffProjectActive">
          deactivate
        </span>
      </template>
      <template slot="main">
        <h1 class="font-light">{{ project.project.projectName }}</h1>
        <div class="flex items-center">
          <div class="mr-4"></div>
          <button
            id="activationToggle"
            @click="toggleStaffProjectStatus(project)"
            class="text-black p-3 -mr-3"
            :class="{
              'text-green-400': project.staffStatus.staffProjectActive,
            }"
          >
            <div class="h-4 pointer-events-none">
              <svg viewBox="0 0 100 100" class="h-full">
                <circle cx="50" cy="50" r="40" fill="currentColor" />
              </svg>
            </div>
          </button>
        </div>
      </template>
      <template slot="about">
        <p
          v-if="project.project.briefing"
          class="text-sm opacity-50 font-light"
        >
          {{ project.project.briefing }}
        </p>
        <div class="text-sm mt-2">
          <p v-if="project.project.manager" class="font-light">
            Project Manager:
            <strong class="font-medium">{{ staff.shortName }}</strong>
          </p>
          <p class="mt-1 font-light">
            Hours spent: {{ currentProjectStats.tot_h || 0 }} /
            {{ project.project.plannedWkf }} h
          </p>

          <div v-if="currentProjectStats.team_ids" class="mt-1 font-light">
            <p>Team:</p>

            <ul class="flex flex-wrap mt-2">
              <li
                class="h-8 w-8 mr-2 mb-2 bg-gray-300 rounded-full overflow-hidden"
                v-for="(member, i) in currentProjectStats.team_ids"
                :key="i"
                :title="member[0]"
              >
                <img
                  v-if="member[1]"
                  :src="imageSrc(member[1])"
                  :alt="member[1]"
                  class="h-full w-full"
                />
              </li>
            </ul>
          </div>
        </div>
      </template>
    </ProjectCardGeneral>
  </ul>
</template>

<script>
import { mapActions, mapState } from "vuex";

import ProjectCardGeneral from "@/components/ProjectCardGeneral";
import api from "@/services/api";

export default {
  components: {
    ProjectCardGeneral,
  },
  props: {
    projects: Array,
  },
  data() {
    return {
      expandedCard: null,
    };
  },
  computed: {
    ...mapState("projects", ["currentProjectStats"]),
    ...mapState("staff", ["currentStaff", "staff"]),
  },
  methods: {
    ...mapActions("projects", ["getProjectStats"]),
    ...mapActions("activestaffprojects", [
      "addActiveStaffProject",
      "deleteActiveStaffProject",
    ]),
    async setExpanded(project) {
      if (this.expandedCard === project.id) {
        this.expandedCard = null;
        return;
      } else {
        if (this.currentProjectStats.project_id != project.id) {
          this.getProjectStats(project.id);

          const data = await api
            .get(project.manager)
            .then((response) => response.data);

          this.$store.commit("staff/setStaff", data);
        }
        this.expandedCard = project.id;
      }
    },
    imageSrc(memberSrc) {
      return process.env.VUE_APP_API_BACK_URL + "/uploads/" + memberSrc;
    },
    async toggleStaffProjectStatus(project) {
      console.log(project);

      if (project.staffStatus.staffProjectActive) {
        console.log(
          "Deactivate by ID",
          project.staffStatus.staffProjectActiveId
        );
        await this.deleteActiveStaffProject(
          project.staffStatus.staffProjectActiveId
        );
      } else {
        console.log("Activate Project for user", project.project.url);
        await this.addActiveStaffProject({
          staff: this.currentStaff.url,
          project: project.project.url,
        });
      }
    },
  },
};
</script>

<style></style>
